import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';

function goToEULA() {
  window.location.replace('/eua-and-pp');
}

const EULAgreement = () => {
  const [agreed, setAgreed] = useState(false);
  const navigate = useNavigate();
  
  // Check if user is logged in
  useEffect(() => {
    if (!Cookies.get('apiToken')) {
      alert("Redirecting to AWS Cognito because you don't have a cookie.");
      window.location.replace('https://' + process.env.REACT_APP_COGNITO_AUTH_DOMAIN + '/login?' +
                  'response_type=code&' +
                  'client_id=' + process.env.REACT_APP_COGNITO_CLIENT_ID + '&' +
                  'redirect_uri=' + process.env.REACT_APP_APP_URL + '/callback');
    }
  }, []);

  const handleAgree = () => {
    // Set a cookie that expires in 365 days to remember user's agreement
    Cookies.set('eulaAgreed', 'true', { expires: 365 });
    navigate('/');
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ my: 4 }}>
        <Paper elevation={3} sx={{ p: 4 }}>
          <Typography variant="h4" component="h1" sx={{ mb: 4 }}>
            Terms of Service & Privacy Policy
          </Typography>
          
          <Typography variant="body1" sx={{ mb: 3 }}>
            Before using this application, you must agree to our Terms of Service and Privacy Policy.
          </Typography>

          <Stack spacing={3}>
            <Button variant="contained" onClick={goToEULA}>
              View Terms of Service & Privacy Policy
            </Button>

            <FormControlLabel
              control={
                <Checkbox 
                  checked={agreed} 
                  onChange={(e) => setAgreed(e.target.checked)} 
                />
              }
              label="I have read and agree to the Terms of Service and Privacy Policy"
            />

            <Button 
              variant="contained" 
              disabled={!agreed} 
              onClick={handleAgree}
              sx={{ width: '200px' }}
            >
              Submit
            </Button>
          </Stack>
        </Paper>
      </Box>
    </Container>
  );
};

export default EULAgreement;