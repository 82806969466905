import * as React from 'react';
import Cookies from 'js-cookie';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { loggedIn, logout, connectedToSpotify, ConnectSpotify, connectedToAppleMusic, ConnectAppleMusic, DisconnectSpotify, PurgeAppleMusicTracks } from './sharedFunctions';
import GetSpotifyProfile from './GetSpotifyProfile';

function goToEULA() {
  window.location.replace('/eua-and-pp');
}

const AccountPage = () => {
  if (Cookies.get('apiToken')) {
    console.log('User is authenticated');
  } else {
    alert("Redirecting to AWS Cognito because you don't have a cookie.");
    window.location.replace('https://' + process.env.REACT_APP_COGNITO_AUTH_DOMAIN + '/login?' +
                'response_type=code&' +
                'client_id=' + process.env.REACT_APP_COGNITO_CLIENT_ID + '&' +
                'redirect_uri=' + process.env.REACT_APP_APP_URL + '/callback');
    console.log('User is not authenticated');
  }
  return (
    <Container maxWidth="sm">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" sx={{ mb: 2 }}>
          Account
        </Typography>
        <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
          App Login
        </Typography>
        { loggedIn() &&
          <div>
              {Cookies.get('email')}?
          </div>
        }
        { loggedIn() &&
        <div>
            <Button onClick={logout} variant="contained">Logout</Button>
        </div>
        }
        <div>
          <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
            Spotify account
          </Typography>
        { loggedIn() && !connectedToSpotify() &&
        <div>
            <Button onClick={ConnectSpotify} variant="contained">Connect Spotify</Button>
        </div>
        }
        { loggedIn() && connectedToSpotify() &&
        <div>
            { GetSpotifyProfile() }
            <Button onClick={DisconnectSpotify} variant="contained">Disconnect Spotify</Button>
        </div>
        }
        </div>
        <div>
          <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
            Apple Music account
          </Typography>
        { loggedIn() && !connectedToAppleMusic() &&
        <div>
          <Button onClick={ConnectAppleMusic} variant="contained">Connect Apple Music</Button>
        </div>
        }
        { loggedIn() && connectedToAppleMusic() &&
        <div>
            You are connected to Apple Music. We can't show you anything else because Apple's API sucks!
        </div>
        }
        { loggedIn() && connectedToSpotify() && (['ethan@electionspace.com'].includes(Cookies.get('email'))) &&
        <div>
          <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
            Maintenance
          </Typography>
          <Button onClick={PurgeAppleMusicTracks} variant="contained">Purge Apple Music Tracks</Button>
        </div>
        }
        { loggedIn() &&
        <div>
          <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
            End User License Agreement and Privacy Policy
          </Typography>
          <Button onClick={goToEULA} variant="contained">View Terms of Service & Privacy Policy</Button>
        </div>
        }
        </div>
        </Box>
    </Container>
  );
  };
  
  export default AccountPage;