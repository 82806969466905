import * as React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import WelcomePage from "./WelcomePage.js"
import FriendsPage from './FriendsPage';
import AccountPage from './AccountPage.js';
import EUAAndPP from './EUAandPP.js';
import EULAgreement from './EULAgreement.js';
import Callback from "./Callback.js"
import CallbackSpotify from "./CallbackSpotify.js"
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';

// Protected route component that checks for EULA agreement
const ProtectedRoute = ({ children }) => {
  // Only redirect to agreement page if user is logged in but hasn't agreed to EULA
  if (Cookies.get('apiToken') && !Cookies.get('eulaAgreed')) {
    return <Navigate to="/eula-agreement" />;
  }
  
  return children;
};

function App() {
  if (!(Cookies.get('currentTab'))) {
    Cookies.set('currentTab', 0);
  }

  const [value, setValue] = React.useState(Number(Cookies.get('currentTab')));

  return (
    <Container maxWidth="sm">
      <Box sx={{ width: 500 }}>
      <BrowserRouter>
          <Routes>
            <Route path="/" element={
              <ProtectedRoute>
                <WelcomePage/>
              </ProtectedRoute>
            }/>
            <Route path="/friends" element={
              <ProtectedRoute>
                <FriendsPage/>
              </ProtectedRoute>
            }/>
            <Route path="/account" element={
              <ProtectedRoute>
                <AccountPage/>
              </ProtectedRoute>
            }/>
            <Route path="/eua-and-pp" element={<EUAAndPP/>}/>
            <Route path="/eula-agreement" element={<EULAgreement/>}/>
            <Route path="/callback" element={<Callback/>} />
            <Route path="/callback-spotify" element={<CallbackSpotify/>} />
          </Routes>
          <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
            Cookies.set('currentTab', newValue);
          }}
        >
          <BottomNavigationAction component={Link} to="/" label="Home" icon={<HomeIcon />} />
          <BottomNavigationAction component={Link} to="/friends" label="Friends" icon={<Diversity1Icon />} />
          <BottomNavigationAction component={Link} to="/account" label="Account" icon={<AccountCircleIcon />} />
        </BottomNavigation>
      </Paper>
        </BrowserRouter>
      </Box>
    </Container>
  );
}

export default App;