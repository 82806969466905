import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

function Callback() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get('code')

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://' + process.env.REACT_APP_API_DOMAIN + '/v1/callback-app?code=' + code +
          '&redirect_uri=' + process.env.REACT_APP_APP_URL + '/callback'
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        console.log(jsonData)
        Cookies.set('apiToken', jsonData['id_token'], { expires: 7 })
        Cookies.set('email', jsonData['email'], { expires: 7 })
        if (jsonData['connected_to_spotify'] === 'true') {
          Cookies.set('connectedToSpotify', jsonData['connected_to_spotify'], { expires: 7 })
        }
        if (jsonData['connected_to_apple_music'] === 'true') {
          Cookies.set('connectedToAppleMusic', jsonData['connected_to_apple_music'], { expires: 7 })
        }

        // Check if user has already agreed to EULA
        if (Cookies.get('eulaAgreed')) {
          navigate('/');
        } else {
          navigate('/eula-agreement');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, []); // Empty dependency array ensures this effect runs only once on mount

  return (
    <div>
      {loading && <p>Getting Cognito auth...</p>}
    </div>
  )
}

export default Callback;